<template>
  <base-section id="about-our-product">
    <base-section-heading
      color="grey lighten-2"
      title="Loan Process"
    />

    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div>
            <p>It starts with a conversation.</p>
            <p>We design a lending plan to match up with your goals. With that in mind, once you find a deal and have it under contract, we match you and your deal up with the best lenders on our platform for your specific deal. Once the lender is decided, we’ll build the doc list that the lender requires and help you get your loan pushed through to closing.</p>
            <p>Pre-Qualification – Once we have the conversation about what you’re working on, if you need a pre-qualification letter, we’ll send you a doc list for a pre-qualified letter. Upon approval of your documents, you’ll send in your deal details and we’ll send you a letter.</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Our Company',
          subtitle: '',
          text: 'Our company has provided independent claims adjusting services to the insurance industry since 1993.  We currently have over 150 individually owned and operated branch offices throughout the United States.  Our branch offices have an average of more than 25 years of Property and Casualty adjusting experience.  Those services include property, general liability, auto, trucking, cargo, product liability, premises liability and many other lines of insurance adjusting.',
          callout: '01',
        },
        {
          title: 'Our Mission',
          subtitle: '',
          text: 'Our mission is to be the premier national independent adjusting firm by serving our customers, our colleagues and the communities where we live.',
          callout: '02',
        },
        {
          title: 'Our Values',
          subtitle: '',
          text: 'Corporately and individually we will: Approach every single day with professionalism, integrity, honesty, excellence, respect, and passion with a view towards results for our customers.',
          callout: '03',
        },
      ],
    }),
  }
</script>
